import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Developers`}</MDXTag>
      <MDXTag name="h3" components={components}>{`We know you want to get stuck in so we'll keep it short...`}</MDXTag>
      <MDXTag name="p" components={components}>{`DCDC uses a blockchain as a governing platform and make its infrastructure available for others to enable and create applications and services. You don't have to be a member of the cooperative to use our network, and everything you need to create your own private networks is available as open source.`}</MDXTag>
      <MDXTag name="p" components={components}>{`(download/build/doc links for groundloop goes here)`}</MDXTag>
      <MDXTag name="h3" components={components}>{`About the groundloop Blockchain`}</MDXTag>
      <MDXTag name="p" components={components}>{`groundloop is DCDC's single source of truth and our publicly-available (to both members and non-members) blockchain. groundloop is developed from a fork of the NEO blockchain source code. NEO uses a Proof of Stake consensus mechanism that uses 2 primary crypotcurrencies, stake and gas. Stake is required to generate gas to enact contracts on the chain however gas is also purchaseable.`}</MDXTag>
      <MDXTag name="p" components={components}>{`groundloop borrows the dual currency model of NEO, and keeps it's energy efficient Proof of Stake consensus.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Owned and Operated`}</MDXTag>
      <MDXTag name="p" components={components}>{`Unlike most other blockchain networks, there is no cost to deploying contracts/dApps on groundloop however all proposed contracts must be vetted by the cooperative. The vetting process assures code quality/safety, provides guarantees to consumers and importantly ensures protection of individual developers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We want provide anyone who wants to create decentralised applications with a platform to do so.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`On Intellectual Property and Open Source`}</MDXTag>
      <MDXTag name="p" components={components}>{`DCDC are an open source cooperative. Wherever we can, we will publish our in house work under open source licenses.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The use of either i. the cooperative's identity and associated media and ii. the cooperative's product identities is prohibited by any person or entity outside direct membership of the cooperative unless exceptions to the contrary are approved by the membership.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The DCDC, DCDC, groundloop, JUPP and associated media are trademarks of The Digital Cooperative Development Consortium Ltd.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Questions and Answers`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Why do we assert trademarks?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Primarily because copycat products and services could damage the reputation of the cooperative.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Are you in the business of buying and selling cryptocurrencies?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The short answer is no.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We issue share certificates digitally using the same technology that cryptocurrencies use. However, for every share that is issued, a certain amount of groundloop compute time is banked against it.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This means that if a member owns 100 shares, for the sake of example, then every day that 100 shares entitles that member to 100 seconds of compute time on groundloop. If that member needs more time per day, they might buy more shares (which may be impractical) or buy/acquire compute time from other members. A member can also sell the time they have to a person or persons outside of the membership.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We are still deliberating on whether to allow persons outside the membership to further resell compute time to other none-members, and whether in such instances that exported time would evaporate or expire or otherwise diminish in value.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Links`}</MDXTag>
      <MDXTag name="p" components={components}>{`Coming soon...`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    